import React from "react"
import { TextTypography } from "../../SharedResources/Typography/TextTypography/TextTypography"
import { FontStyle } from "../../SharedResources/Typography/typography.service"
import "./DecisionMaker.scss"

interface DecisionMakerProps {
  imagePosition?: "right" | "left"
  title: string
  description: string
  imageSrc?: string
  videoSrc?: string
  imageClassName?: string
}
const DecisionMaker: React.FC<DecisionMakerProps> = ({
  imageSrc,
  videoSrc,
  imagePosition = "left",
  title,
  description,
  imageClassName,
}) => {
  return (
    <div className="flex justify-center mt-16 container relative z-10">
      <div
        className={`flex ${
          imagePosition === "left" ? "flex-row-reverse lg:-ml-28" : "flex-row"
        }  flex-wrap max-w-4xl lg:flex-no-wrap`}
      >
        <div className="flex flex-col justify-center w-full mx-4 mb-16 lg:w-3/5 lg:mb-0 lg:mx-0">
          <TextTypography className="text-4xl" fontStyle={FontStyle.BOLD}>
            {title}
          </TextTypography>
          <TextTypography
            className="text-xl mt-6"
            fontStyle={FontStyle.REGULAR}
          >
            {description}
          </TextTypography>
        </div>
        {!!imageSrc && (
          <div className="flex flex-col justify-center w-full lg:w-2/5 px-4">
            <img
              className={
                "decision-maker-mobile-screen mx-auto " + imageClassName
              }
              alt="Event Detail"
              src={imageSrc}
            />
          </div>
        )}

        {!!videoSrc && (
          <div className="flex justify-center w-full lg:w-2/5 px-4 decision-maker-video-screen">
            <video
              autoPlay={true}
              muted
              loop
              className="rounded-3xl border-8 border-neutral-900 decision-maker-mobile-screen mx-auto "
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </div>
  )
}

export default DecisionMaker
