import React from "react"
import AppFooter from "../AppFooter/AppFooter"
import CurveContainer from "../CurveContainer/CurveContainer"
import BriefingViewMobileScreen from "../../images/dualMobileScreen.png"
import AskQuestionMobileScreen from "../../images/askQuestionMobileScreen.png"
import AskAQuestionMobileScreen from "../../images/askAQuestionMobileScreen.png"
import EventDetailsMobileScreen from "../../images/eventDetailsSingleMobileScreen.png"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import AppHeader from "../V2/AppHeader"
import "./DomeHomePage.scss"
import DecisionMaker from "./DecisionMaker/DecisionMaker"
import HomePageBackgroundContainer from "../V2/HomePageBackgroundContainer"
import DecisionMakerFormWithCard from "../SignUp/DecisionMakerSignUp/DecisionMakerFormWithCard"

const DomeHomePage: React.FC = () => {
  return (
    <section className="w-full bg-white">
      <AppHeader />
      <HomePageBackgroundContainer
        mainHeading={`The Best Policy Events Platform \n Is A Click Away`}
        buttonText="Get Started"
        onButtonClick={() =>
          document
            .getElementById("decision_maker_form")
            ?.scrollIntoView({ behavior: "smooth" })
        }
        className="pb-44"
      />
      <article>
        <div className="relative -mt-28">
          <CurveContainer color="light">
            <>
              <div className="p-2" />
              <div className="flex flex-col md:flex-row mx-auto -mt-96 justify-center md:space-x-32">
                <div className="flex flex-col items-center">
                  <TextTypography
                    className="text-4xl"
                    color={TextColor.WHITE}
                    fontStyle={FontStyle.SEMIBOLD}
                  >
                    Academics
                  </TextTypography>
                  <TextTypography
                    className="text-3xl font-semibold"
                    color={TextColor.WHITE}
                    fontStyle={FontStyle.ITALIC}
                  >
                    Always transparent
                  </TextTypography>
                  <img
                    className="relative z-10 single-mobile-screen mt-6 w-auto"
                    alt="Event Detail"
                    src={EventDetailsMobileScreen}
                  />
                </div>
                <div className="flex flex-col items-center mt-5 md:mt-0">
                  <TextTypography
                    className="text-4xl md:text-white"
                    fontStyle={FontStyle.SEMIBOLD}
                  >
                    Decision-makers
                  </TextTypography>
                  <TextTypography
                    className="text-3xl font-semibold md:text-white"
                    fontStyle={FontStyle.ITALIC}
                  >
                    Anonymous by default
                  </TextTypography>
                  <img
                    className="relative z-10 single-mobile-screen mt-6 w-auto"
                    alt="Event Detail"
                    src={AskAQuestionMobileScreen}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center max-w-700 mx-auto pt-16 p-5">
                <TextTypography
                  className="text-4xl"
                  color={TextColor.BLACK}
                  fontStyle={FontStyle.SEMIBOLD}
                >
                  How does it work?
                </TextTypography>
                <TextTypography
                  className="text-xl mt-6"
                  color={TextColor.BLACK}
                  fontStyle={FontStyle.REGULAR}
                >
                  Experts from universities, think tanks, and foundations can
                  create events and briefings on the Dome platform expressly for
                  decision-makers.
                </TextTypography>
                <TextTypography
                  className="text-xl mt-6"
                  color={TextColor.BLACK}
                  fontStyle={FontStyle.REGULAR}
                >
                  Academics can share information about themselves, their
                  research, and their recommendations on policy on the Dome
                  Academic Registry.
                </TextTypography>
                <TextTypography
                  className="text-xl mt-6"
                  color={TextColor.BLACK}
                  fontStyle={FontStyle.REGULAR}
                >
                  Decision-makers are never tracked and user data is never
                  shared. On Dome, the experts are always transparent.
                </TextTypography>
              </div>
            </>
          </CurveContainer>
        </div>
      </article>
      <article className="mt-40 pb-32" id="decision_makers">
        <div className="container mx-auto flex justify-center">
          <TextTypography
            fontStyle={FontStyle.BOLD}
            className="text-center text-5xl -mt-20"
          >
            Decision-makers
          </TextTypography>
        </div>
        <div>
          <DecisionMaker
            videoSrc={
              "https://dome-content-dev.s3.us-east-1.amazonaws.com/message-attachment-video/PQYhQBGhbRrG"
            }
            imagePosition="right"
            description="Stay ahead of the curve and get expert information on the issues that are front of mind in the policy landscape today."
            title="Find events on topics that you need to know"
          />
        </div>
        <div className="lg:-mt-20">
          <DecisionMaker
            imageSrc={AskQuestionMobileScreen}
            description="Get information from advocates without revealing your identity. Dome allows decision-makers to ask questions anonymously, so you can get an answer without revealing yourself."
            title="Ask Questions Anonymously"
          />
        </div>
        <div className="lg:-mt-20">
          <DecisionMaker
            imagePosition="right"
            imageSrc={BriefingViewMobileScreen}
            description="Dome has the best events on the Hill. Find events happening today based on the issues that you value most. Dome Events is free to use and never tracks users."
            title="Find the best events tonight"
          />
        </div>
      </article>
      <HomePageBackgroundContainer
        mainHeading={`Get Involved without \n Getting Tracked`}
        className="py-32"
      />
      <div className="-mt-28" id="decision_maker_form">
        <CurveContainer color="light" hideBottomCurve>
          <div>
            <div className="pb-20">
              <DecisionMakerFormWithCard />
            </div>
            <AppFooter />
          </div>
        </CurveContainer>
      </div>
    </section>
  )
}

export default DomeHomePage
