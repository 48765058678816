import * as React from "react"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import DomeHomePage from "../components/DomeHomePage/DomeHomePage"

const IndexPage = () => {
  return (
    <main>
      <FaviconHeader />
      <DomeHomePage />
    </main>
  )
}

export default IndexPage
